import * as React from 'react'
import { Link } from 'gatsby'
import { Helmet } from "react-helmet"
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  container,
  navLinkText
} from './layout.module.css'

const Layout = ({ pageTitle, children }) => {
  return (
    <div className={container}>
      <title>Get Into Investing! by William F. Seeds</title>

      <div className="application">
        <Helmet>
          <html lang="en" amp />
          <meta charSet="utf-8" />
          <meta name="description" content="A simple strategy on how to Get Into Investing!, using only your smartphone." />
          <link rel="canonical" href="https://getintoinvesting.com" />
          <link rel="apple-touch-icon" href="https://getintoinvesting.com/apple-touch-icon-57x57.png" />
          <link rel="apple-touch-icon" sizes="72x72" href="https://getintoinvesting.com/apple-touch-icon-72x72.png" />
          <script type="application/ld+json">{`
          { "@context": "http://schema.org" }
          `}</script> 
        </Helmet>
        
      </div>

      <header>
            <Link to="/" style={{ textEmphasisColor: `black`, backgroundImage: `none`, textDecoration: 'none'}}>
            <h2 style={{ display: `inline`, textDecorationColor:`black` }}>Get Into Investing!</h2></Link>
            <ul style={{ listStyle: `none`, float: `right` }}>
            <Link to="/learn" className={navLinkText} style={{ textDecoration: 'none' }}>Learn</Link>  |  <Link to="/buy" className={navLinkText} style={{ textDecoration: 'none' }}>Buy</Link>  |  <Link to="/social" className={navLinkText} style={{ textDecoration: 'none' }}>Social</Link>
            </ul>
      </header>

      <main>
        {children}
      </main>

      <footer>
        <nav>
          <br />
          <p>
          <ul style={{ listStyle: `none`, float: `left`, className:`navLinks`, textDecoration: 'none' }}>
            <Link to="/learn" className={navLinkText} style={{ textDecoration: 'none' }}>Learn</Link>  |  <Link to="/buy" className={navLinkText} style={{ textDecoration: 'none' }}>Buy</Link>  |  <Link to="/social" className={navLinkText} style={{ textDecoration: 'none' }}>Social</Link>
            <br />
            <Link to="/" className={navLinkText} style={{ textDecoration: 'none' }}>&#169; 2021-2024  GetIntoInvesting.com</Link>
          </ul>
          </p>
        </nav>
      </footer>

      <br />

    </div>

  )
}

export default Layout